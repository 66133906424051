<script setup lang="ts">
import type { Game } from "@/types";

const { t } = useT();

defineProps<{
	game: Game;
}>();
</script>

<template>
	<div v-if="game.isNew" class="badges-row">
		<ABadge variant="info" background="var(--custom-13)" autosize>
			<AText :size="10" :modifiers="['bold', 'uppercase']">{{ t("New") }}</AText>
		</ABadge>
	</div>
</template>

<style scoped lang="scss">
.badges-row {
	display: flex;
	align-items: center;
	gap: 4px;
}
</style>
